<template>
  <v-app id="login" class="primary pa-0 ma-0">
    <v-container fluid class="primary fill-height pa-0 ma-0">
      <v-flex d-flex child-flex class="primary fill-height">
        <v-row no-gutters>
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 7"
            class="fill-height main-background-login pa-0 ma-0"
          >
            <v-sheet
              color="transparent"
              class="fill-height d-flex flex-column justify-start"
              height="100%"
            >
              <!-- ERROR MESSAGE -->
              <MarketplaceDialog
                v-if="
                  $vuetify.breakpoint.mobile &&
                  isAppError &&
                  !isAccessTokenError
                "
                :show="showMarketplaceDialog"
                @close="closeMarketplaceDialog"
              />
              <ScopesDialog
                v-if="
                  $vuetify.breakpoint.mobile &&
                  !isAppError &&
                  isAccessTokenError &&
                  !isMsAccessTokenError
                "
                :show="showScopesDialog"
                @close="closeScopesDialog"
              />
              <MsScopesDialog
                v-if="
                  $vuetify.breakpoint.mobile &&
                  !isAppError &&
                  !isAccessTokenError &&
                  isMsAccessTokenError
                "
                :show="showScopesDialog"
                @close="closeScopesDialog"
              />
              <ErrorDialog
                v-if="
                  $vuetify.breakpoint.mobile &&
                  !isAppError &&
                  !isAccessTokenError
                "
                :show="hasAnError"
                :error-message="getErrorMessage"
                @close="setAuthError(false)"
              />

              <!--LOGIN SLOGAN -->
              <SloganDesktopLogin v-if="!$vuetify.breakpoint.mobile" />

              <v-spacer v-if="!$vuetify.breakpoint.mobile" />

              <!-- FIXING POWERED BY  -->
              <v-card-actions
                v-else
                :class="`${
                  $vuetify.breakpoint.xs ? 'justify-end' : 'justify-center'
                } px-0 pt-12 ma-0 flex-column`"
              >
                <Logo
                  product="conecta_control"
                  :height="70"
                  full
                  white
                  no-margins
                />

                <small class="white--text no-line-height pt-4"
                  >Powered by</small
                >
                <Logo
                  product="conecta_suite"
                  full
                  white
                  width="120"
                  no-margins
                />
              </v-card-actions>

              <v-spacer v-if="$vuetify.breakpoint.sm" />

              <!-- FEATURED LIST -->
              <LoginFeaturesList v-if="!$vuetify.breakpoint.mobile" />

              <v-spacer v-if="$vuetify.breakpoint.xs" />

              <LoginActions v-if="$vuetify.breakpoint.mobile" />
              <LoginFooter v-if="$vuetify.breakpoint.mobile" />

              <v-card-actions
                class="d-flex align-center justify-center text-center"
                v-if="$vuetify.breakpoint.mobile"
              >
                <SelectLanguage />
                <FAQButton />
              </v-card-actions>
            </v-sheet>
          </v-col>

          <v-col
            v-if="!$vuetify.breakpoint.mobile"
            cols="5"
            style="position: relative"
          >
            <MarketplaceDialog
              v-if="isAppError && !isAccessTokenError"
              :show="showMarketplaceDialog"
              @close="closeMarketplaceDialog"
            />

            <ScopesDialog
              v-if="!isAppError && isAccessTokenError && !isMsAccessTokenError"
              :show="showScopesDialog"
              @close="closeScopesDialog"
            />
            <MsScopesDialog
              v-if="!isAppError && !isAccessTokenError && isMsAccessTokenError"
              :show="showScopesDialog"
              @close="closeScopesDialog"
            />

            <v-sheet
              color="grey lighten-3"
              class="fill-height d-flex flex-column justify-space-between"
            >
              <v-card
                tile
                flat
                color="transparent"
                class="ma-auto"
                max-width="500"
              >
                <v-card
                  tile
                  flat
                  color="transparent"
                  class="d-flex justify-center pa-0 my-0 mx-auto"
                  max-width="400"
                >
                  <Logo
                    product="conecta_control"
                    :heigth="logoHeight"
                    full
                    no-margins
                  />
                </v-card>

                <v-subheader
                  class="font-weight-medium d-flex justify-center text-body-1 mb-5"
                >
                </v-subheader>

                <ErrorDialog
                  v-if="!isAppError && !isAccessTokenError"
                  :show="hasAnError"
                  :error-message="getErrorMessage"
                  @close="setAuthError(false)"
                />

                <LoginActions />

                <div class="d-flex flex-column align-center mt-8 trasnparent">
                  <strong class="no-line-height font-weight-medium"
                    >Powered by</strong
                  >
                  <Logo
                    product="conecta_suite"
                    :heigth="logoHeight"
                    full
                    grey
                    width="150"
                    no-margins
                  />
                </div>
              </v-card>

              <LoginFooter />

              <v-card-actions
                class="mb-4 d-flex align-center justify-center text-center align-self-center"
              >
                <SelectLanguage />
                <FAQButton />
              </v-card-actions>
            </v-sheet>
          </v-col>
        </v-row>
      </v-flex>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ErrorDialog from "@/components/general/ErrorDialog.vue";
import SloganDesktopLogin from "@/components/login/SloganDesktopLogin";
import LoginFeaturesList from "@/components/login/LoginFeaturesList";
import LoginActions from "@/components/login/LoginActions";
import LoginFooter from "@/components/login/LoginFooter";
import FAQButton from "@/components/forms/buttons/FAQButton";
import SelectLanguage from "@/components/general/SelectLanguage";
import MarketplaceDialog from "@/components/login/MarketplaceDialog.vue";
import ScopesDialog from "@/components/login/ScopesDialog.vue";
import MsScopesDialog from "@/components/login/MsScopesDialog.vue";

export default {
  name: "Login",
  components: {
    ErrorDialog,
    SloganDesktopLogin,
    LoginFeaturesList,
    LoginActions,
    LoginFooter,
    FAQButton,
    SelectLanguage,
    MarketplaceDialog,
    ScopesDialog,
    MsScopesDialog,
  },

  data() {
    return {
      showMarketplaceDialog: true,
      showScopesDialog: true,
    };
  },

  computed: {
    ...mapGetters(["authError"]),

    hasAnError() {
      return !!this.authError;
    },

    isAppError() {
      return this.authError === "MARKETPLACE_APP_NOT_INSTALLED";
    },

    isAccessTokenError() {
      return this.authError === "INVALID_GOOGLE_ACCESS_TOKEN";
    },

    isMsAccessTokenError() {
      return this.authError === "INVALID_MICROSOFT_ACCESS_TOKEN";
    },

    logoHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 60;
      } else if (this.$vuetify.breakpoint.md) {
        return 70;
      } else if (this.$vuetify.breakpoint.sm) {
        return 60;
      } else {
        return 40;
      }
    },

    getErrorMessage() {
      const messages = {
        INVALID_GRANT: this.$ml.get("error_invalid_grant"),
        MISSING_CODE: this.$ml.get("error_missing_code"),
        NO_DOMAIN_ASSOCIATED: this.$ml.get("error_no_domain_associated"),
        NO_TENANT_ASSOCIATED: this.$ml.get("error_no_tenant_associated"),
        UNREGISTERED_USER: this.$ml.get("error_unregistered_user"),
        MARKETPLACE_APP_NOT_INSTALLED: this.$ml.get(
          "error_marketplace_not_installed"
        ),
        AZURE_APP_NOT_INSTALLED: this.$ml.get("error_azure_not_installed"),
        INVALID_GOOGLE_ACCESS_TOKEN: this.$ml.get(
          "error_invalid_google_access_token"
        ),
        INVALID_MICROSOFT_ACCESS_TOKEN: this.$ml.get(
          "error_invalid_microsoft_access_token"
        ),
        NOT_WORKSPACE_ADMIN: this.$ml.get("error_not_workspace_admin"),
        INVALID_CALLBACK: this.$ml.get("error_invalid_callback"),
        FORBIDDEN_CUSTOMER: this.$ml.get("error_forbidden_customer"),
        INVALID_TOKEN: this.$ml.get("error_invalid_token"),
        LOGIN_REQUIRED: this.$ml.get("error_login_required"),
        UNKNOWN: this.$ml.get("error_unknown"),
        COMPANY_BANNED: this.$ml.get("error_company_banned"),
      };
      return this.authError ? messages[this.authError] : "";
    },
  },
  methods: {
    ...mapMutations(["setAuthError"]),

    closeMarketplaceDialog() {
      this.showMarketplaceDialog = false;
    },
    closeScopesDialog() {
      this.showScopesDialog = false;
    },
  },

  beforeMount() {
    localStorage.removeItem("hubspotToken");
  },
};
</script>

<style scoped>
.trasnparent {
  opacity: 0.5;
}

.no-line-height {
  line-height: 0;
}

.main-background-login {
  background-image: url("../assets/images/login.png");
  background-position: 20% 95%;
  background-size: cover;
}

@media (max-width: 320px) {
  .main-background-login {
    background-position: center right;
  }
}

@media (max-width: 600px) {
  .main-background-login {
    background-position: 40% 15%;
  }
}

@media (min-width: 601px) and (max-width: 1024) {
  .main-background-login {
    background-position: 90% 15%;
  }
}
</style>
